
import RestroCard, { withPromotedLabel } from "./RestroCard";
import { useEffect, useState } from "react";
import Shimmer from "./Shimmer";
import { Link } from "react-router-dom";
import useOnlineStatus from "../../utils/useOnlineStatus";
import ResCarousel from "./ResCarousel";
import { RES_CAROUSEL_LINK } from "../../utils/constants";



const Body = () => {
    const [reslogo,setResLogo] = useState([])
    const [res,setres] = useState([])
    const [effect,setEffect] = useState(null)
    const [filter,setfilter] = useState([])
    const onlineStatus = useOnlineStatus();
    const [currentIndex,setCurrentIndex] = useState(0)

    useEffect(() => {
        fetchdata();
    },[]);
     
    const fetchdata = async () => { 
    const data = await fetch("https://www.swiggy.com/dapi/restaurants/list/v5?lat=20.27060&lng=85.83340&is-seo-homepage-enabled=true&page_type=DESKTOP_WEB_LISTING");
    const json = await data.json();
    setres(json?.data?.cards[1]?.card?.card?.gridElements?.infoWithStyle?.restaurants)
    setfilter(json?.data?.cards[1]?.card?.card?.gridElements?.infoWithStyle?.restaurants)
    setResLogo(json?.data?.cards[0]?.card?.card?.imageGridCards?.info)
}
const previousSlide = () => {
    setCurrentIndex(x => 
        (x === 0 ? res.length - 7 : x-1 )
    )
}

const nextSlide = () => {
    setCurrentIndex((prevIndex) => (
    prevIndex === res.length - 7 ? 0 : prevIndex + 1
    ));
};

const sortDelivery = () => {
    effect === "delivery"
        ?(setfilter(res),setEffect(null))
        :(setfilter([...res].sort((a,b)=>a.info.sla.deliveryTime - b.info.sla.deliveryTime)),setEffect("delivery"))
}
const topRated = () => {
    const handleClick = res.filter((restaurant) => restaurant.info.avgRating > 4.0)
    {
    effect === "topRated"
    ?(setfilter(res),setEffect(null))
    :(setfilter(handleClick),setEffect("topRated"))
}}

const pureVeg = () => {
    const handleClick = res.filter((restaurant) => restaurant.info.veg === true)
    {
        effect === "pureVeg"
        ?(setfilter(res),setEffect(null))
        :(setfilter(handleClick),setEffect("pureVeg"))
    }
} 
const priceRange300_600 = () => {
    const handleClick = res.filter((restaurant) => {
        const str = restaurant.info.costForTwo;
        const num = parseInt(str.replace(/\D/g,""),10)
        return num >= 300 && num <= 600
        })
        {
            effect === "priceRange300_600"
            ?(setfilter(res),setEffect(null))
            :(setfilter(handleClick),setEffect("priceRange300_600"))
        }
}
const priceRange_300 = () => {
    const handleClick = res.filter((restaurant) => {
        const str = restaurant.info.costForTwo;
        const num = parseInt(str.replace(/\D/g,""),10)
        return num <= 300
    })
    {
        effect === "price"
        ?(setfilter(res),setEffect(null))
        :(setfilter(handleClick),setEffect("price"))
    }
}
const RestroCardPromoted = withPromotedLabel(RestroCard)

const getCollectionIdFromLink = (link) => {
    const urlParams = new URL(link).searchParams;
    return urlParams.get("collection_id");
}



if( onlineStatus === false) return <h1> youre offline</h1>
return (res?.length === 0 && reslogo.length === 0) ? (<Shimmer/>)  
:(
        <div className="body">
            <div>
            <div className="border-b-2">
            <h1 className="font-extrabold xl:text-2xl lg:2xl md:text-xl sm:text-[18px] text-[16px] pl-5 py-3">What's on your mind?</h1>
            <div className="flex overflow-hidden ">
            <button className="p-3" onClick={previousSlide}>⇦</button>
                {reslogo?.slice(currentIndex,currentIndex+7).map((restaurant,index) => (
                   <Link to ={ "/sortlist/" + getCollectionIdFromLink(restaurant?.action?.link) }>
                    <div key={index} className=" hover:shadow-xl">
                        <img src={RES_CAROUSEL_LINK + restaurant.imageId}
                        className="w-full"/>       
                    </div>
                    </Link>
                 
                ))}
                <button className="p-3" onClick={nextSlide}>⇨</button>
            </div>
            </div>
            </div>
            <h1 className="font-extrabold xl:text-2xl lg:2xl md:text-xl sm:text-[18px] text-[16px] pl-5 py-3">Restaurants with online food delivery in Bhubaneswar</h1>
            <div className="flex m-2 xl:text-2xl lg:text-xl md:text-lg sm:text-md text-xs font-semibold"> 
                <button className={`mx-3 p-2 shadow-md shadow-slate-400  ${effect==="topRated" ?"bg-black border-2 text-white hover:border-2 border-red-600" : "hover:border-2 border-black"}`} 
                onClick={topRated}
                >
                    Rating 4.0+
                </button>
                <button className={`mx-3 p-2 shadow-md shadow-slate-400  ${effect==="delivery" ?"bg-black border-2 text-white hover:border-2 border-red-600 " : "hover:border-2 border-black"}`}
                onClick = {sortDelivery}
                   >
                    Sort By Delivery Time
                </button>
                <button className={`mx-3 p-2 shadow-md  shadow-slate-400  ${effect ==="pureVeg" ? "bg-black border-2 text-white hover:border-2 border-red-600" : "hover:border-2 border-black"}`}
                onClick={pureVeg}>
                    Pure Veg
                </button>
                <button className={`mx-3 p-2 shadow-md  shadow-slate-400  ${effect ==="priceRange300_600" ? "bg-black border-2 text-white hover:border-2 border-red-600" : "hover:border-2 border-black"}`}
                onClick={priceRange300_600}>
                    Rs. 300-Rs. 600
                </button>
                <button className={`mx-3 p-2 shadow-md  shadow-slate-400  ${effect ==="price" ? "bg-black border-2 text-white hover:border-2 border-red-600" : "hover:border-2 border-black"}`}
                onClick={priceRange_300}>
                   Less than Rs. 300
                </button>
            </div>
            <div className="grid  xl:grid-cols-6 lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-4 grid-cols-3 gap-4 p-4 h-screen overflow-x-scroll">
                {
                filter?.map(restaurant => 
                    <Link key={restaurant.info.id}
                    to = {"/resmenu/" + restaurant.info.id}
                    > {restaurant.info.aggregatedDiscountInfoV3 ? (
                        <RestroCardPromoted resData={restaurant}/>
                    ) :
                    (<RestroCard  resData= {restaurant}/>

                    )}
                    </Link>
                        
                   
                )
            }
            </div>
        </div>
    )
}
export default Body;
