import { useEffect, useState} from "react";
import Shimmer from "./Shimmer";
import {FOODItem_URL,RES_MENU_URL} from "../../utils/constants"
import { useParams } from "react-router";
import useResturant from "../../utils/useRestaurant";
import ResCategory from "./ResCategory";
import { IoPinSharp,IoStarOutline,IoBicycleOutline  } from "react-icons/io5";
import { IoIosStar } from "react-icons/io";



const ResMenu = () => {
    const {resId} = useParams()
    const resinfo = useResturant(resId)
    const[showindex,setShowIndex] = useState(0)
    
    if (resinfo === null) return <Shimmer/>
    const categories = resinfo?.cards[4]?.groupedCard?.cardGroupMap?.REGULAR?.cards.filter( (card) => card.card?.card?.["@type"] === "type.googleapis.com/swiggy.presentation.food.v2.ItemCategory"
    );
    const toggleArrow = () => {
      setArrow(x => !x)
    }
    const {name,costForTwoMessage,avgRating,totalRatingsString,areaName,cuisines,sla,feeDetails} = resinfo.cards[2]?.card?.card?.info
    console.log(categories)
    return ( 
      <div className="relative h-screen">   
        <div className="mt-3 bg-gradient-to-t from-gray-200 to-white  p-3 xl:w-6/12 lg:w-6/12 md:w-6/12 sm:w-4/5 w-4/5 m-auto rounded-xl space-y-3">
                <span className="items-start font-extrabold text-xl">{name}</span>
                <div className="border-2 border-slate-300 bg-white rounded-xl">
                    <ul className="flex flex-col items-start font-semibold p-3 space-y-2">
                      <li className="flex items-start space-x-2"><p className="rounded-full bg-green-600 p-1"><IoIosStar className="text-white"/></p>{avgRating}({totalRatingsString})<span className="text-gray-400">.</span><span>{costForTwoMessage}</span></li>
                      <li className="text-orange-600 underline ">{cuisines.join(",")}</li>
           
                      <li className="flex items-start space-x-2  "><p className="text-gray-400 "><IoPinSharp className="mt-2"/></p>Outlet<span className="text-gray-400 space-x-2">{areaName}</span></li>
                      <li className="flex items-start space-x-2 border-b-2  w-full"><p className="text-gray-400"><IoPinSharp className="rotate-180"/></p>{sla.slaString}</li>
             
                      <li className="flex items-start space-x-2 "><IoBicycleOutline/>{sla.lastMileTravelString} | {feeDetails?.totalFee ? (<div>₹{feeDetails.totalFee/100}Delivery fee will apply</div>) : (null) }</li>
                    </ul>
                </div>
            </div>
        <div>
          {categories?.map((category,index) => (
           
           <ResCategory
           key={category.card.card.title}
           resCategory={category}
           arrow={index === showindex }
           setShowIndex={()=>setShowIndex(index === showindex ? null : index)}
       
           />
          
          ))}
        </div>
      </div>
    )
}
export default ResMenu;