
import { FaGithub, FaInstagram, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";

const Contact = () => {  
    
    return (
        <div className="w-6/12 m-auto p-2">
            <h1 className="text-2xl font-bold">Contact </h1>
            <div >
                <h1 className="text-xl font-semibold">Reach me out:</h1>
                <div className="flex justify-evenly">
                   <a href="https://github.com/thedantonn" target="_blank" >
                        <FaGithub className="text-3xl hover:text-gray-600" />
                    </a>

                    <a href="https://www.linkedin.com/in/nitish-panda-2b2b70254" target="_blank" >
                        <FaLinkedin className="text-3xl hover:text-blue-600" />
                    </a>
                    <a href="https://instagram.com/the_dantonn" target="_blank" >
                        <FaInstagram className="text-3xl hover:text-pink-600" />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Contact;