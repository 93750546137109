import { useEffect, useState } from "react";
import RestroCard from "./RestroCard";
import { Link } from "react-router-dom";
import { CiSearch } from "react-icons/ci";

const Search = () => {
    const [res,setRes] = useState([])
    const [filter,setFilter] = useState([])
    const [search,setSearch] = useState("")
    const fetchData = async () => {
        const data = await fetch ("https://www.swiggy.com/dapi/restaurants/list/v5?lat=20.27060&lng=85.83340&is-seo-homepage-enabled=true&page_type=DESKTOP_WEB_LISTING");
        const json = await data.json();
        setRes(json?.data?.cards[1]?.card?.card?.gridElements?.infoWithStyle?.restaurants)
        console.log(json)
        console.log(json?.data?.cards[1]?.card?.card?.gridElements?.infoWithStyle?.restaurants)
    }
    useEffect(() => {
        fetchData();
    },[])
    const handleSearch = () => {
       if (search === "") ;
       const filtered = res?.filter((restaurant) => 
        restaurant?.info?.name.toLowerCase().includes(search.toLowerCase()) || 
        restaurant?.info?.cuisines.some((cuisines)=>cuisines.toLowerCase().includes(search.toLowerCase())))
       setFilter(filtered)
    }
    const handleClick = (cuisine) => {
        setSearch(cuisine)
        handleSearch()

    }
    const cuisines = ["Pizza" , "Burger" , "Dosa" , "Rolls" , "North Indian" , "Chinese"]
 
    return(
        <div className="" >
            <div className="flex justify-center m-4 p-6">
                <input className="border-2 w-[800] h-10"type="text" value={search}
                placeholder=" Search for restaurants "
                onChange={(e) => {
                    setSearch(e.target.value)
                }}
                />
                <button className="border-2 " onClick={handleSearch}>Search</button>
            </div>
            <div className="flex flex-wrap px-4 pt-5 justify-center">
                {filter.length > 0 ? (
                    filter.map(restaurant =>
                        <Link key = {restaurant.info.id}
                        to = {"/resmenu/" + restaurant.info.id}>
                            <RestroCard resData = {restaurant}/>
                        </Link>
                    )
                ):(<div className="">
                    {cuisines.map((cuisine,index) => (
                        <div key = {index} className="flex justify-evenly space-x-3">
                        <button className="font-bold text-xl p-2" onClick={() => handleClick(cuisine)}>{cuisine}</button>
                        </div>
                    ))}
                </div>)
                }
            </div>
        </div>
    )
}
export default Search;