import { RES_URL } from "../../utils/constants.js";
import { MdStars } from "react-icons/md";


const RestroCard = (props) => {
    const { resData } = props;
    const{name,cuisines,cloudinaryImageId,avgRating,aggregatedDiscountInfoV3}=resData?.info || {}
    console.log(resData)
    return(
        <div className="xl:w-52 xl: lg: lg: md: md: sm: sm: w-32 m-2 hover:border-4 border-white">
          <div className="relative">
          <img className="xl:rounded-3xl lg:rounded-3xl md:rounded-2xl sm:rounded-md rounded-md "
            src={RES_URL + cloudinaryImageId}/>
            {aggregatedDiscountInfoV3 && (
                <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-40 text-white p-2 xl:rounded-b-3xl lg:rounded-b-3xl md:rounded-b-2xl sm:rounded-b-md rounded-b-md flex space-x-1">
                    <h1 className="xl:text-base lg:text-sm md:text-xs sm:text-sm text-sm font-bold">{aggregatedDiscountInfoV3?.header}</h1>
                    <p className="xl:text-base lg:text-sm md:text-xs sm:text-sm text-sm font-semibold">{aggregatedDiscountInfoV3?.subHeader}</p>
                </div>
            )}
          </div>
            <div className="w-auto">
                <h1 className="font-extrabold xl:text-xl lg:xl md:text-lg sm:text-[18px] text-[16px] ">{name}</h1>      
                <h1 className="flex font-semibold xl:text-base lg:base md:text-base sm:text-[18px] text-[13px]"><span><MdStars className="m-1 text-emerald-600"/></span>{avgRating} . {resData?.info?.sla?.slaString}</h1>
                <h1 className="font-extralight xl:text-base lg:base md:text-base sm:text-[18px] text-[13px]">{cuisines?.join(", ")}</h1>
                
            </div>
                
        </div>
    );
};

export const withPromotedLabel = (RestroCard) => {

    return (props) => {
        const {resData} = props
        const {header,subHeader}  = resData?.info?.aggregatedDiscountInfoV3 || {}
        
        return(
            <div className="relative">
                <RestroCard {...props}/>
            </div>
        )
    }
}

export default RestroCard;