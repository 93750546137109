import { useParams } from "react-router";
import useCategory from "../../utils/useCategory";
import Shimmer from "./Shimmer";
import { useEffect, useState } from "react";
import { RES_CAROUSEL_ITEM_URL,RES_URL } from "../../utils/constants";
import { MdStars } from "react-icons/md";
import { Link } from "react-router-dom";

const ResCarouselItems = () => {
    const [resinfo,setresinfo] = useState([])
    const { resId } = useParams();

    useEffect(() => {
        fetchdata()
    },[])
  
    const fetchdata = async () => {
       const data = await fetch (`${RES_CAROUSEL_ITEM_URL}${resId}&tags=layout_CCS_&sortBy=&filters=&type=rcv2&offset=0&page_type=null`); 
       const json = await data.json();
       setresinfo(json?.data?.cards)
       console.log(`${RES_CAROUSEL_ITEM_URL}${resId}&tags=layout_CCS_&sortBy=&filters=&type=rcv2&offset=0&page_type=null`)
   
    }
    return (resinfo.length === 0) ? (<Shimmer/>) : 
    (
    <div>
       <div className="pt-4 px-4 space-y-2">
        <h1 className="text-5xl font-semibold px-1">{resinfo[0]?.card?.card?.title}</h1>
        <p className="text-xl font-extralight px-1">{resinfo[0]?.card?.card?.description}</p>
        <div className="flex flex-wrap">
         {resinfo.slice(3).map((cards,index) => (
            <Link to={"/resmenu/"+cards?.card?.card?.info?.id} key={cards?.card?.card?.info?.name}>
                <div className="w-52 h-[430] m-2 hover:border-2 border-white">
           <div className="relative">
           <img className="rounded-3xl"
            src={RES_URL + cards?.card?.card?.info?.cloudinaryImageId}/>
            {cards?.card?.card?.info?.aggregatedDiscountInfoV3 && (
                <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-40 text-white p-2 rounded-b-3xl flex space-x-1">
                    <h1 className="text-md font-bold">{cards?.card?.card?.info?.aggregatedDiscountInfoV3?.header}</h1>
                    <p className="text-md font-semibold">{cards?.card?.card?.info?.aggregatedDiscountInfoV3?.subHeader}</p>
                </div>)}
          </div>
            <div className="">
                <h3 className="font-extrabold">{cards?.card?.card?.info?.name }</h3>      
                <div className="flex">
                <MdStars className="m-1 text-emerald-500"/><h4 className=" font-extralight">{cards?.card?.card?.info?.avgRating}</h4>
                <h4 className="px-2">.</h4>
                <h4 className=" font-semibold">{cards?.card?.card?.info?.sla?.slaString}</h4>
                <h4 className=" font-semibold">{cards?.card?.card?.info?.sla?.deliveryTime}</h4>
                </div>
                <h4>{cards?.card?.card?.info?.cuisines?.join(", ")}</h4>
                
            </div>
                
        </div>
            </Link>
         ))}
       </div>
       </div>
       </div>
    )
}
export default ResCarouselItems