
import { RES_URL } from "../../utils/constants"
import { useDispatch } from "react-redux"
import { addItem } from "../Components/UserSlice"
import { useState } from "react"

const ResItemList = (props) => {
    const {data} = props
    const dispatch = useDispatch()
    const Item = ({ category }) => {
        const [count, setCount] = useState(0);
        const dispatch = useDispatch();
    
        const handleClick = () => {
            setCount(count + 1);
            dispatch(addItem(category.card.info));
        };
    
        return (
                <button
                    className="border-2 border-gray-200 rounded-lg text-green-500 bg-white px-3 m-2 font-semibold absolute bottom-2 left-1/2 transform -translate-x-1/2"
                    onClick={handleClick}>
                    ADD{count > 0 ? ` (${count})` : ""}
                </button>
        );
    };

    return (
        <div className=" p-3">
            {data.map(category => (
                <div key={category.card.info.id} className="flex p-2 border-b-2">
                    <div className="p-2 flex flex-col w-9/12">
                        <span className="text-xl font-extrabold">{category.card.info.name}</span>
                        <span className="font-bold">₹{category.card.info.price/100 || category.card.info.defaultPrice/100}</span>
                        <span>{category.card.info.description}</span>
                    </div>
                    <div className="p-2 w-3/12 relative">
                        <img  src={RES_URL + category.card.info.imageId}/>
                       <Item category={category}/>
                    </div>
                </div>

            ))}
        </div>
    )
}
export default ResItemList