import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import Header from "./Components/Header";
import Body from "./Components/Body";
import Error from "./Components/Error";
import Contact from "./Components/Contact";
import Cart from "./Components/Cart";
import ResMenu from "./Components/ResMenu";
import {createBrowserRouter, RouterProvider, Outlet} from "react-router-dom";
import Search from "./Components/Search";
import { Provider } from "react-redux";
import appStore from "../utils/appStore";
import ResCarouselItems from "./Components/ResCarouselItems";
import Login from "./Components/Login";

const App = () => {
    return(    
    <div className="app">
         <Provider store = {appStore}>
         <Header/>
        <Outlet/>
        </Provider>
    </div>
    )
}

const About =  lazy ( () => import ("./Components/About"))

const appRouter = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        errorElement: <Error/>,
        children: [{
            path: "/",
            element: <Body/>

        },
           
        {
            path: "/about",
            element: <Suspense>
                <About/>
            </Suspense>
        },
        {
            path: "/contact",
            element: <Contact/>
        },
        {
            path: "/cart",
            element: <Cart/>
        },   
        {
            path: "/resmenu/:resId",
            element: <ResMenu/>
        },
        {
            path: "/sortlist/:resId",
            element: <ResCarouselItems/>
        },
        {
            path: "/search",
            element: <Search/>
        },
        {
            path: "/login",
            element: <Login/>
        },
    ]
    },
   
])

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <RouterProvider router={appRouter}></RouterProvider>
)