import { RES_CAROUSEL_LINK } from "../../utils/constants";
import {useState,useEffect} from "react";
import { SiFoodpanda } from "react-icons/si";

const ResCarousel = () => {
    const [res,setRes] = useState([]);
    const [currentIndex,setCurrentIndex] = useState(0)
    const [loading, setLoading] = useState(true); 
   

    useEffect(() => {
        fetchData();
    },[])
    const fetchData = async () => {
        const data = await fetch("https://www.swiggy.com/dapi/restaurants/list/v5?lat=20.27060&lng=85.83340&is-seo-homepage-enabled=true&page_type=DESKTOP_WEB_LISTING")
        const json = await data.json();
        setRes(json?.data?.cards[0]?.card?.card?.imageGridCards?.info)
        setLoading(false)
       
    }
    
    
    const previousSlide = () => {
        setCurrentIndex(x => 
            (x === 0 ? res.length - 7 : x-1 )
        )
    }
    
    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (
        prevIndex === res.length - 7 ? 0 : prevIndex + 1
        ));
};

 
    return(
       
        <div className="">
            {!loading && (
            <div className="border-b-2">
            <h1 className="font-extrabold text-2xl pl-5 py-3">What's o your mind?</h1>
            <div className="flex overflow-hidden ">
            <button className="p-3" onClick={previousSlide}>⇦</button>
                {res.slice(currentIndex,currentIndex+7).map((restaurant,index) => (
                    <div key={index} className="rounded-full hover:shadow-xl">
                        <img src={RES_CAROUSEL_LINK + restaurant.imageId}
                        className="w-full h-64 "/>       
                    </div>
                ))}
                <button className="p-3" onClick={nextSlide}>⇨</button>
            </div>
            </div>
             )}
        </div>
    )
}
export default ResCarousel;