import { useDispatch, useSelector } from "react-redux";
import ResItemList from "./ResItemList";
import { clearCart } from "./UserSlice";
import { useNavigate } from "react-router";
import { CART_ITEM_LOGO } from "../../utils/constants";


const Cart = () => {
    const navigate = useNavigate()
    const cartItems = useSelector((store) => store.user.cart.items)
    console.log(cartItems?.[0])
    console.log(cartItems?.[1])
    const dispatch = useDispatch()
    const handleClearCart = () => {
        dispatch(clearCart())
    }
    const uniqueItems = cartItems.reduce((acc,item) => {
        const existingItem = acc.find((x) => x.id === item.id)
        if (existingItem) {
            existingItem.count += 1
            existingItem.totalprice += item?.price || item?.defaultPrice
        }
        else{
            acc.push({...item,count:1,totalprice:item?.price || item?.defaultPrice})
        }
        return acc
    },[])
    
    const Bill = () => {
        const TotalBill = uniqueItems.reduce((sum,item) => sum + item.totalprice ,0)
        const TotalItem = cartItems.length
        return(
            <div className=" border-black mx-4">
                <div className="w-full flex ">
                    <div className="w-10/12 text-xl border-2 rounded-3xl mt-2 border-x-purple-800 border-y-gray-500  p-2 ">
                    <h1>We are pleased to serve you anytimee,anyplace,anywhere,anymood,anyemotions.</h1>
                    <p>WE BELIEVE IN HUNGRY PANDA TO REVERT FOOD CRISIS IN INDIA 🇮🇳 </p>
                    </div>
                    <div className="w-2/12 ">
                        <h1 className="text-2xl text-green-700 font-bold mt-4 border-2">Grand Total: ₹{TotalBill / 100}</h1>
                        <h1 className="text-2xl font-bold mt-4">Total Item: ₹{TotalItem}</h1>  
                    </div>
                </div> 
            </div>
        )
    }
    
    return(
        <div className="items-centre text-center m-4 p-4">
            <h1>"Thank you for dining with us! Here's a summary of your fine selections:"</h1>
            {uniqueItems.length === 0 ? 
            (
            <div className="flex flex-col justify-center items-center">
                <img 
                className="w-[271] m-auto h-[256]"
                src = "https://media-assets.swiggy.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/2xempty_cart_yfxml0"/>
                <h1 className="text-gray-900 font-semibold pt-4">Your cart is empty</h1>
                <h2 className="text-gray-500 font-medium pb-4">You can go to home page to view more restaurants</h2>
                <button 
                className="bg-orange-600 w-62 p-2  text-white hover:shadow-xl"
                onClick={() => {
                    navigate('/')
                }}>SEE RESTAURANTS NEAR YOU</button>
            </div>
            ) :
            ( 
            <div className=" p-4 space-y-3 bg-gradient-to-r from-white  to-gray-60">
                 <div>
                    {uniqueItems.map((item) => (
                        <div key={item.id} className="w-10/12 m-auto flex ">
                            <img  className= "p-2 w-2/12 relative rounded-full border-x-2 border-black shadow-md" src={`${CART_ITEM_LOGO}${item.imageId}`}/>
                            <h1 className="font-bold text-2xl m-auto w-4/12 hover:text-red-700">{item.name}</h1>
                           <div className="flex flex-col justify-center text-xl font-bold">
                           <h1>Quantity : <span className="">{item.count}</span></h1>
                           <h1>Total : {item.price/100 || item?.defaultPrice/100}</h1>
                           </div>
                        </div>
                    ))}
                 </div> 
                 <Bill/>
                 <button onClick={handleClearCart}
                 className="border-2 rounded-full w-20 h-20 bg-red-600  text-white hover:shadow-xl hover:text-white hover:bg-black"><span className="text-2xl font-bold">Clear</span></button>
               <button 
                 className="border-2 rounded-lg p-4   bg-slate-700  text-white hover:shadow-xl hover:text-white hover:bg-black"><span className="text-2xl font-bold">Procced To Pay</span></button>
               
        
            </div>
            ) }
        </div>
    )
}

export default Cart;
