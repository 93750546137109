import { useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router"
import { login } from "./UserSlice"

const Login = () => {
    const [name,setName] = useState("")
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleClick = () => {
        if(name === ""){
           const confirmation = window.confirm("Do you want continue without loggin in?")
           if (confirmation){
            navigate("/")
           }
        }
        else{
            dispatch(login({name}))
            navigate('/')
        }
    }
    return(
        <div className="m-auto p-4 w-6/12 space-y-3 ">
            <h1 className="text-2xl"> Login </h1>
            <div className="flex flex-col">
                <input 
                className="mb-2 h-12 p-2"
                type="text" 
                placeholder="name"
                onChange={(e) => setName(e.target.value)}
                />
                <button className="border-2 border-red-600 p-2 rounded-md text-black hover:bg-black hover:text-white"
                onClick={handleClick}>Submit</button>
            </div>
        </div>
    )
}
export default Login