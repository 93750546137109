import {createSlice} from "@reduxjs/toolkit"


const UserSlice = createSlice({
    name: "users",
    initialState: {
        isAuthenticated: false,
        userInfo: null,
        cart: {
            items: [],
        }
    },
    reducers:{
        login: (state,action) => {
            state.isAuthenticated = true;
            state.userInfo = action.payload;
        },
        logout: (state,action) => {
            state.isAuthenticated =  false;
            state.userInfo = null;
            state.cart.items = []
        },
        addItem:(state,action) => {
            state.cart.items.push(action.payload)
        },
        
        clearCart:(state) => {
            state.cart.items = []
        }
    }
})

export const {login,logout,addItem,clearCart} = UserSlice.actions
export default UserSlice.reducer