import { useEffect,useState } from "react";
import { RES_CAROUSEL_ITEM_URL } from "./constants";

const useCategory = () => {
    const [resinfo, setresinfo] = useState(null);
    useEffect (() => {
        menu();
    },[])
    
    const menu = async() => {
        const data = await fetch ("");
        const json = await data.json();
        setresinfo(json?.data)
        
    }
    return resinfo
}
export default useCategory