import { useState } from "react";
import ResItemList from "./ResItemList"
import { FaArrowUp } from "react-icons/fa";
import { FaArrowDown } from "react-icons/fa";

const ResCategory = (props) => {
    const handleArrow = () => {
        setShowIndex()
      
    }
    const {resCategory,setShowIndex,arrow} = props
    const {title,id} = resCategory.card?.card
    
    return(
        <div className="mt-3 xl:w-6/12 lg:w-6/12 md:w-6/12 sm:w-4/5 w-4/5 mx-auto">
            <div className=" shadow-lg shadow-slate-400  bg-white">
             <div className="flex justify-between px-2">
             <h1 className="font-semibold text-xl m-2 p-2">{title}({resCategory.card.card.itemCards.length})</h1>
             <button onClick={handleArrow}>
               {arrow ? <FaArrowUp/> : <FaArrowDown/>}
             </button>
             </div>
             {arrow && <ResItemList data = {resCategory.card.card.itemCards}/>
            
             }
        </div> 
        </div>
    )
}
export default ResCategory