import { useEffect,useState } from "react";
import { RES_MENU_URL } from "./constants";

const useResturant = (resId) => {
    const [resinfo, setresinfo] = useState(null);
    useEffect (() => {
        menu();
    },[resId])
    
    const menu = async() => {
        const data = await fetch (RES_MENU_URL + resId);
        const json = await data.json();
        setresinfo(json?.data)
        
    }
    return resinfo
}
export default useResturant