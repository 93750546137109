import { useState } from "react";
import { Link } from "react-router-dom";
import { SiFoodpanda } from "react-icons/si";
import { BsCart4 } from "react-icons/bs";
import { FaRegUser } from "react-icons/fa";
import { GiBlacksmith } from "react-icons/gi";
import { CiSearch } from "react-icons/ci";
import { useSelector,useDispatch } from "react-redux";
import useOnlineStatus from "../../utils/useOnlineStatus";
import '../index.css'
import { login, logout, clearCart } from "./UserSlice"


const Header = () => {
    const dispatch = useDispatch()
    const onlineStatus = useOnlineStatus();
    const CartItems = useSelector((store) => store.user.cart.items)
    console.log(CartItems)
    const user = useSelector((store) => store.user)

    const handleLogout = () => {
        dispatch(logout());
        dispatch(clearCart()); 
    };


    return(
        <div className = "flex justify-between  p-4 shadow-lg">       
            <Link to = "/"> <SiFoodpanda className="xl:text-5xl lg:text-4xl md:text-3xl sm:text-2xl text-xl"/> </Link> 
            <div className = "flex">
            <ul className="flex xl:space-x-10 lg:space-x-8 md:space-x-6 sm:space-x-4 space-x-2 xl:text-2xl lg:text-xl md:text-lg sm:text-sm text-xs text-slate-900">
                   <li className="flex items-center"><CiSearch/><Link to = "/search" >Search</Link></li>
                   <li className="flex items-center">{onlineStatus ? "🟢" : "🔴"}Online </li>
                   <li className="flex items-center" >
                   {user.isAuthenticated ? (
                    <div className="flex space-x-2 items-center">
                        <h1>Welcome,{user.userInfo.name}</h1>
                        <FaRegUser/><button onClick={handleLogout}>Logout</button>
                    </div>
                   ) : (
                    <Link to ="/login"><div className="flex items-center"><FaRegUser/><button>Login</button></div></Link>
                   )}</li>
                   <li className="flex items-center"><GiBlacksmith/><Link to = "/about">About</Link></li>
                   <li className="flex items-center"><GiBlacksmith/><Link to = "/contact">Contact</Link></li>
                   <li className="flex items-center"><BsCart4/><Link to = "/cart">Cart({CartItems.length})</Link></li>
                </ul>
            </div>
        </div>
    )
}
export default Header;