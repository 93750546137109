import { BsCart4 } from "react-icons/bs"
import { CiSearch } from "react-icons/ci"
import { FaRegUser } from "react-icons/fa"
import { GiBlacksmith } from "react-icons/gi"
import { SiFoodpanda } from "react-icons/si"
const Shimmer = () => {
    return (
        <div className="">
            
          <div className="flex flex-col">
         
             <div className= "bg-black h-72">
                 <div className=" flex mt-14 flex-col items-center relative ">
                 <p className=" border-1 mt-4 rounded-full text-5xl relative z-20 bg-white"><SiFoodpanda/></p>
                 <div className="absolute ">
                 <div className="w-20 h-20 border-8 border-t-4 border-t-transparent border-gray-500 rounded-full animate-spin"></div>
                  </div>
                 <h1 className="text-3xl flex justify-center mt-6 text-slate-500">Looking for great food near you ...</h1>
              </div>
             
             </div>
          </div>
            
            <div className="flex m-2"> 
                <button className={`mx-3 p-2 shadow-md rounded-full w-32 h-10 bg-gray-400`} 
                
                >
                    
                </button>
                <button className={`mx-3 p-2 shadow-md rounded-full w-32 h-10 bg-gray-400`}
                
                   >
                    
                </button>
                <button className={`mx-3 p-2 shadow-md rounded-full w-32 h-10 bg-gray-400`}
                >
       
                </button>
                <button className={`mx-3 p-2 shadow-md rounded-full w-32 h-10 bg-gray-400`}
                >
                
                </button>
                <button className={`mx-3 p-2 shadow-md rounded-full w-32 h-10 bg-gray-400`}>
                </button>
            </div>
        <div className="flex flex-wrap px-4 pt-5">
            <div className="w-52 h-72 m-2  border-2 bg-gray-400"></div>
            <div className="w-52 h-72 m-2  border-2 bg-gray-400"></div>
            <div className="w-52 h-72 m-2  border-2 bg-gray-400"></div>
            <div className="w-52 h-72 m-2  border-2 bg-gray-400"></div>
            <div className="w-52 h-72 m-2  border-2 bg-gray-400"></div>
            <div className="w-52 h-72 m-2  border-2 bg-gray-400"></div>
            <div className="w-52 h-72 m-2  border-2 bg-gray-400"></div>
            <div className="w-52 h-72 m-2  border-2 bg-gray-400"></div>
            <div className="w-52 h-72 m-2  border-2 bg-gray-400"></div>
            <div className="w-52 h-72 m-2  border-2 bg-gray-400"></div>
            <div className="w-52 h-72 m-2  border-2 bg-gray-400"></div>
            <div className="w-52 h-72 m-2  border-2 bg-gray-400"></div>
            
        </div>
        </div>
      
    )
}
export default Shimmer
